
<template>
    <div class="auto-assessment-config">
      <CSTabBar :tabbar="{1: '巡检自动考核', 2: '工单自动考核', 3: '考勤自动考核'}" :checked-tab="autoAssessmentType"
                @changeTabBar="(index) => autoAssessmentType = index"
      />
      <div v-show="autoAssessmentType === 1">
        <div class="row">
          <div class="col-lg-12">
            <div class="ibox">
              <div class="ibox-content">
                <div class="row text-middle">
                  <div>
                    <span>员工，在规定巡检时间内未完成巡检计划，应扣</span
                  >
                    <CSSelect style="padding-left: 5px;">
                      <select
                          v-model="inspectTimeoutDeductScore"
                      >
                        <option value="">
                          请选择
                        </option>
                        <option :value="0">0</option>
                        <option
                            v-for="n in 20"
                            :value="n"
                            :key="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                    </CSSelect>
                  </div>

                  <button
                      class="btn btn-primary ml-1"
                      @click="saveAutoConfig"
                  >
                    保存
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="autoAssessmentType === 2">
        <div class="row">
          <div class="col-lg-12">
            <div class="ibox">
              <div class="ibox-content">
                <div class="row mb-3 text-middle">
                  <div>
                    <span>员工，在规定判定时限内未判定工单是否有效，应扣</span
                  >
                    <CSSelect>
                      <select
                          v-model="ticketTimeoutDeductScore"
                      >
                        <option value="">请选择</option>
                        <option
                            v-for="n in scoreOptions"
                            :value="n"
                            :key="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                    </CSSelect>
                  </div>

                  <button
                      class="btn btn-primary ml-1"
                      @click="saveAutoConfig"
                  >
                    保存
                  </button>
                </div>
                <div class="row mb-3 text-middle">
                  <div>
                    <span>工单处理后，用户评价为“1星~3星”，所参与员工，每人应扣</span
                  >
                    <CSSelect>
                      <select
                          v-model="ticketNegativeRatingDeductScore"
                      >
                        <option value="">请选择</option>
                        <option
                            v-for="n in scoreOptions"
                            :value="n"
                            :key="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                    </CSSelect>
                  </div>
                  <button
                      class="btn btn-primary ml-1"
                      @click="saveAutoConfig"
                  >
                    保存
                  </button>
                </div>
                <div class="row mb-3 text-middle">
                  <div>
                    <span>工单处理后，用户评价为“5星”，所参与员工，每人应加</span
                  >
                    <CSSelect>
                      <select
                          v-model="ticketPositiveRatingAwardScore"
                      >
                        <option value="">请选择</option>

                        <option
                            v-for="n in scoreOptions"
                            :value="n"
                            :key="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                    </CSSelect>
                  </div>

                  <button
                      class="btn btn-primary ml-1"
                      @click="saveAutoConfig"
                  >
                    保存
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="autoAssessmentType === 3">
        <div class="rule-card">
          <div class="rule-card-title">
            自动扣分规则：
          </div>
          <div class="rule-card-content">
            <div class="rule-item">
              <span>员工打卡迟到，应扣</span>
              <CSSelect>
                <select v-model="attendanceLateDeductScore">
                  <option value="">请选择</option>
                  <option :value="0">0</option>
                  <option v-for="n in 20" :key="n" :value="n">{{n}}</option>
                </select>
              </CSSelect>
              <button class="btn btn-primary" @click="saveAutoConfig">保存</button>
            </div>

            <div class="rule-item">
              <span>员工打卡早退，应扣</span>
              <CSSelect>
                <select v-model="attendanceLeaveEarlyDeductScore">
                  <option value="">请选择</option>
                  <option :value="0">0</option>
                  <option v-for="n in 20" :key="n" :value="n">{{n}}</option>
                </select>
              </CSSelect>
              <button class="btn btn-primary" @click="saveAutoConfig">保存</button>
            </div>

            <div class="rule-item">
              <span>员工缺卡，应扣</span>
              <CSSelect>
                <select v-model="attendanceCardMissingDeductScore">
                  <option value="">请选择</option>
                  <option :value="0">0</option>
                  <option v-for="n in 20" :key="n" :value="n">{{n}}</option>
                </select>
              </CSSelect>
              <button class="btn btn-primary" @click="saveAutoConfig">保存</button>
            </div>

            <div class="rule-item">
              <span>员工旷工，应扣</span>
              <CSSelect>
                <select v-model="attendanceAbsenteeismDeductScore">
                  <option value="">请选择</option>
                  <option :value="0">0</option>
                  <option v-for="n in 20" :key="n" :value="n">{{n}}</option>
                </select>
              </CSSelect>
              <button class="btn btn-primary" @click="saveAutoConfig">保存</button>
            </div>
          </div>
        </div>
        <div class="rule-card">
          <div class="rule-card-title">
            自动加分规则：
          </div>
          <div class="rule-card-content">
            <div class="rule-item">
              <span>员工上月考勤无异常，应加</span>
              <CSSelect>
                <select v-model="attendanceNoAbnormalityAwardScore">
                  <option value="">请选择</option>
                  <option :value="0">0</option>
                  <option v-for="n in 20" :key="n" :value="n">{{n}}</option>
                </select>
              </CSSelect>
              <button class="btn btn-primary" @click="saveAutoConfig">保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import CSTabBar from "@/components/common/CSTabBar";
import {
    queryAutomationConfigRulesUrl,
    setupAutomationConfigRulesUrl,
} from "@/requestUrl";

const AUTO_ASSESSMENT_TYPE = {
    INSPECTION: 1,
    WORK_ORDER: 2,
    ATTENDANCE: 3,
};
export default {
    props: {},
    components: {
        CSSelect,
        CSTabBar,
    },
    data() {
        return {
            autoAssessmentType: AUTO_ASSESSMENT_TYPE.INSPECTION,
            inspectTimeoutDeductScore: 0,
            ticketTimeoutDeductScore: 0,
            ticketNegativeRatingDeductScore: 0,
            ticketPositiveRatingAwardScore: 0,
            attendanceLateDeductScore: 0,
            attendanceLeaveEarlyDeductScore: 0,
            attendanceCardMissingDeductScore: 0,
            attendanceAbsenteeismDeductScore: 0,
            attendanceNoAbnormalityAwardScore: 0,
            scoreOptions: []
        };
    },
    mounted() {
        for(let i = 0; i <= 20; i+=0.5) {
            this.scoreOptions.push(i);
        }
        this.getAutoConfig();
    },
    methods: {
        getAutoConfig() {
            this.$fly
                .post(queryAutomationConfigRulesUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    operatorId: this.$vc.getCurrentStaffInfo().id,
                })
                .then((res) => {
                    const typeMapping = {
                        1: "inspectTimeoutDeductScore",
                        2: "ticketTimeoutDeductScore",
                        3: "ticketNegativeRatingDeductScore",
                        4: "ticketPositiveRatingAwardScore",
                        5: "attendanceLateDeductScore",
                        6: "attendanceLeaveEarlyDeductScore",
                        7: "attendanceCardMissingDeductScore",
                        8: "attendanceAbsenteeismDeductScore",
                        9: "attendanceNoAbnormalityAwardScore",
                    };
                    if (res.code === 0) {
                        res.data.forEach((val) => {
                            this[typeMapping[val.type]] = val?.score || 0;
                        });
                    }
                });
        },
        saveAutoConfig() {
            let params = {
                regionId: this.$vc.getCurrentRegion().code,
                operatorId: this.$vc.getCurrentStaffInfo().id,
                inspectTimeoutDeductScore: this.inspectTimeoutDeductScore,
                ticketTimeoutDeductScore: this.ticketTimeoutDeductScore,
                ticketNegativeRatingDeductScore: this.ticketNegativeRatingDeductScore,
                ticketPositiveRatingAwardScore: this.ticketPositiveRatingAwardScore,
                attendanceLateDeductScore: this.attendanceLateDeductScore,
                attendanceLeaveEarlyDeductScore: this.attendanceLeaveEarlyDeductScore,
                attendanceCardMissingDeductScore: this.attendanceCardMissingDeductScore,
                attendanceAbsenteeismDeductScore: this.attendanceAbsenteeismDeductScore,
                attendanceNoAbnormalityAwardScore: this.attendanceNoAbnormalityAwardScore,
            };
            this.$fly
                .post(setupAutomationConfigRulesUrl, params)
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("保存成功");
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.auto-assessment-config
  color #000
  .text-middle
      display flex
      flex-flow row nowrap
      align-items center
      span, button
          margin-right 10px
          display inline-block
          vertical-align middle
      button.btn
          width 50px
          height 30px
          padding 0
          margin-left 30px !important
  .rule-card
    background #FFFFFF
    color #000
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, .1)
    font-size 14px
    &:not(:last-child)
      margin-bottom 20px
    &-title
      padding 15px 30px
      line-height 14px
      font-weight bold
      color #000
      border-bottom 1px solid #eee
    &-content
      padding 15px 30px
      .rule-item
        & > span
          display inline-block
          vertical-align middle
        .cs-select
          margin-left 10px
        .btn-primary
          margin-left 30px
          width 50px
          font-size 14px
          height 30px
          padding 0
        &:not(:last-child)
          margin-bottom 20px
</style>
